*{
    font-family: Helvetica, sans-serif;
}

section{
  padding: 0px;
  margin: 0px;
}

.btn{
    border-radius: 100px !important;
}

.btn-primary{
    background-color: black !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: bold !important;
    border: none !important;
}

.primary-color{
  color: #003F66 !important
}

.btn-primary:hover{
  color: #00AADF !important;
  background-color: transparent !important;
  border: 1px solid black !important;
}

html {font-size: 112.5%;} /*18px*/

body {
  background: white;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 3rem 0 1.38rem;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
  font-weight: 800;
}

h2 {font-size: 2.441rem;}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}